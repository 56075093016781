@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.dashboard-content {
  max-width: 1200px;
  max-height: fit-content;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.metric-selector {
  margin-bottom: 20px;
}

.metric-selector label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.metric-selector select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chart-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

button:hover {
  background-color: #0056b3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.time-range-slider {
  width: 100%;
  margin-top: 20px;
}

.time-range-slider input {
  width: 100%;
}

.time-range-slider div {
  font-weight: bold;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  flex: 1;
  overflow-y: auto;
}

.dark {
  background-color: #1a202c;
  color: #e2e8f0;
}

.marker-cluster-custom {
  background-color: #1e88e5;
  color: white;
}

.marker-cluster-violated {
  background-color: #e53935;
  color: white;
}

.marker-cluster-custom div,
.marker-cluster-violated div {
  background-color: rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
  line-height: 30px;
}
.custom-div-icon svg {
  width: 100%;
  height: 100%;
}

.halo-icon svg {
  width: 100%;
  height: 100%;
}

